import { NgTemplateOutlet } from '@angular/common';
import {
	Component,
	ContentChild,
	EventEmitter,
	Input,
	OnDestroy,
	Output,
	TemplateRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { RowaActivityIndicatorModule } from '../../../../../libs/ui/src/lib/activity-indicator/activity-indicator.module';
import { ComponentHeaderComponent } from '../component-header/component-header.component';
import { SearchInputComponent } from '../search-input/search-input.component';
import { PageWrapperService } from './page-wrapper.service';

@Component({
	selector: 'app-page-wrapper',
	template: `
		<div class="flex flex-col h-full">
			<div class="flex-initial">
				<app-component-header
					[TITLE]="TITLE"
					[DESCRIPTION]="DESCRIPTION"
				/>
				<ng-container [ngTemplateOutlet]="messages"></ng-container>
				<div class="flex justify-end my-3">
					@if (showLoader) {
						<span class="mr-2">
							<rowa-activity-indicator />
						</span>
					}
					<app-search-input
						class="w-1/3"
						[(value)]="searchValue"
						(valueChange)="search.emit(searchValue)"
					/>
				</div>
			</div>
			<div class="overflow-hidden flex-auto">
				<ng-container [ngTemplateOutlet]="content" />
			</div>
		</div>
	`,
	styles: [],
	standalone: true,
	imports: [
		ComponentHeaderComponent,
		NgTemplateOutlet,
		RowaActivityIndicatorModule,
		SearchInputComponent,
	],
})
export class PageWrapperComponent implements OnDestroy {
	@Input() TITLE: string;
	@Input() DESCRIPTION: string;
	@Output() search: EventEmitter<string> = new EventEmitter();
	@ContentChild('messages', { read: TemplateRef, static: false })
	messages: TemplateRef<unknown>;
	@ContentChild('content', { read: TemplateRef, static: false })
	content: TemplateRef<unknown>;

	showLoader: boolean = false;
	searchValue: string = '';

	subs: Subscription = new Subscription();

	constructor(private pagewrapper: PageWrapperService) {
		//todo: create service for loader
		this.subs.add(
			this.pagewrapper.searchLoader$.subscribe(
				(show: boolean) => (this.showLoader = show),
			),
		);

		this.subs.add(
			this.pagewrapper.searchValue$.subscribe(
				(value: string) => (this.searchValue = value),
			),
		);
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe();
		this.pagewrapper.searchValue$.next('');
	}
}
